.post-preview-main {
  .posts .post_list .post {
    border-top: 1px solid #d2d2d2;
    padding-top: 20px;
    display: flex;
  }

  .posts .post_list .post .post_left {
    float: left;
    width: 900px;
    padding-bottom: 25px;
    padding-right: 16px;
  }

  .posts .post_list .post .post_left .post_title_row {
    min-height: 40px;
  }

  .posts .post_list .post .post_left .post_title_row .post_title {
    display: inline-block;
    font-weight: 700;
    color: #000;
    font-size: 18px;
  }

  .posts .post_list .post .post_left .post_tags_row {
    padding-bottom: 10px;
  }

  .posts .post_list .post .post_left .post_tags_row div {
    vertical-align: middle;
    margin-right: 15px;
  }

  .posts .post_list .post .post_left .post_tags_row .post_tags {
    display: inline-block;
  }

  .posts .post_list .post .post_left .post_tags_row .post_tags ul {
    list-style: none;
    padding: 0;
    margin: 0;
    vertical-align: middle;
  }

  .posts .post_list .post .post_left .post_tags_row .post_tags ul li {
    color: #fff;
    display: inline-block;
    padding: 5px 7px;
    line-height: 1.7em;
    margin-right: 15px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
    vertical-align: text-top;
  }

  .posts .post_list .post .post_left .post_tags_row .post_tags ul li.tag_country {
    border: 1px solid brown;
    color: brown;
  }

  .posts .post_list .post .post_left .post_tags_row .post_tags ul li:last-child {
    margin-right: 0;
  }

  .posts .post_list .post .post_left .post_tags_row .post_tags ul li.tag_green {
    background: #8EC63F;
  }

  .posts .post_list .post .post_left .post_tags_row .post_tags ul li.tag_brown {
    background: #695636;
  }

  .posts .post_list .post .post_left .post_tags_row .post_tags ul li.tag_grey {
    background: #A3A3A5;
  }

  .posts .post_list .post .post_left .post_tags_row .post_expire {
    font-size: 17px;
  }

  .posts .post_list .post .post_left .post_tags_row .post_permalink {
    width: 20px;
    height: 20px;
    background-color: blue;
    display: inline-block;
  }

  .posts .post_list .post .post_left .post_tags_row .post_source {
    display: inline-block;
  }

  .posts .post_list .post .post_left .post_tags_row .post_source_link {
    display: inline-block;
  }

  .posts .post_list .post .post_left .post_tags_row .post_source_link span {
    padding-left: 5px;
    color: #634b2f;
  }

  .posts .post_list .post .post_left .post_data .post_text p {
    margin: 0;
  }

  .posts .post_list .post .post_right {
    float: left;
    width: 270px;
    margin-bottom: 25px;
  }

  .posts .post_list .post .post_right .post_bookmark .post_fav {
    margin-right: 5px;
    display: inline-block;
    cursor: pointer;
    font-size: 20px;

    &, .anticon {
      color: #e3bc81;
    }
  }

  .posts .post_list .post .post_right .post_bookmark .post_fav .anticon {
    opacity: 0.3;
  }

  .posts .post_list .post .post_right .post_bookmark .post_fav:hover .anticon {
    opacity: 1;
  }

  .posts .post_list .post .post_right .post_bookmark .post_fav span {
    color: #000;
  }

  .posts .post_list .post .post_right .post_email button {
    background: none;
    outline: none;
    border: none;
    text-align: left;
    padding: 0;
    cursor: pointer;

    input[type="submit"] {
      font-size: 14px;
      outline: 1px solid grey;
      border-radius: 0;
      border: none;
    }
  }

  .posts .post_list .post .post_right .post_email a:hover, .posts .post_list .post .post_right .post_email button:hover {
    text-decoration: none;
  }

  .posts .post_list .post .post_right .post_email .input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }

  .posts .post_list .post .post_right .post_email input[type=text] {
    width: 190px;
    border: 1px solid grey;
    display: block;
    height: 40px;
    padding: 0 15px;
    color: #000000;
    background-color: rgba(255, 255, 255, 0.15);
    margin-right: 1%;
    font-size: 13px;
    outline: none;
  }

  .posts .post_list .post .post_right .post_email input[type=submit] {
    height: 40px;
  }

  .posts .post_list .post .post_right .post_email span {
    font-size: 20px;
  }

  .posts .post_list .post .post_right a, .posts .post_list .post .post_right button {
    color: #000;
  }

  .posts .post_list .post .post_right a .anticon, .posts .post_list .post .post_right button .anticon {
    color: #e3bc81;
    padding-right: 5px;
  }

  .posts .post_list .post .post_right a:hover {
    text-decoration: underline;
  }

  .clear {
    clear: both;
  }

  .selected_filters {
    margin-bottom: 20px;
    height: 32px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .selected_filters #selected_filters {
    display: inline-block;
  }

  .selected_filters #selected_filters .selected-filter-button {
    opacity: 0.7;
    background-color: #e3bc81;
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 5px;
    margin-right: 5px;
  }

  .selected_filters #selected_filters .selected-filter-button:hover {
    opacity: 1;
  }

  .breadcrumbs {
    display: none;
    margin-top: 20px;
  }

  .breadcrumbs .breadcrumbs-pages {
    display: inline-block;
  }

  .breadcrumbs a {
    padding: 3px 7px;
  }

  .breadcrumbs a:hover, .breadcrumbs a.active {
    background-color: #e3bc81;
    color: #fff;
  }

  @media screen and (max-width: 610px) {
    .posts .search .search_tags {
      margin-top: 10px;
    }
    .posts .search .search_tags ul {
      display: block;
    }
    .posts .search .search_tags ul li {
      display: block;
      max-width: 300px;
      margin: auto;
    }
    .posts .tabs .tab {
      display: block;
    }
    .posts .tabs .tab span {
      display: block;
    }
  }
  @media screen and (max-width: 1000px) {
    .posts .post_left {
      width: 100% !important;
    }
  }

  .hidden {
    display: none;
  }

  .pointer {
    cursor: pointer;
  }
}
