$primary: #daa520;

.mail-container {
  display: flex;
  flex-direction: column;
  background: white;
  padding: 0 32px;
  position: relative;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(5px);
    z-index: 1;
  }

  &.no-data {
    justify-content: center;
    align-items: center;

    .create-template-btn {
      position: absolute;
      z-index: 100;

      button {
        width: 240px;
        height: 50px;
      }
    }
  }

  .copy-btn {
    position: absolute;
    right: 24px;
    top: 24px;
  }

  .mail-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 40px;
    padding: 60px 0;

    img.logo {
      width: 300px;
    }

    span.title {
      font-size: 22px;
      color: $primary;
    }
  }

  .mail-main {
    background: #f5efe1;
    border-radius: 6px;
    padding: 60px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
    margin-bottom: 60px;

    > div {
      width: 560px;
      background: #fcf9ee;
      padding: 32px;
      display: flex;
      flex-direction: column;

      hr {
        margin: 8px 0;
      }
    }

    .mail-title-list {
      gap: 16px;

      span.title {
        font-size: 18px;
        font-weight: bold;
        margin-top: 32px;

        &:first-of-type {
          margin-top: 0;
        }
      }

      a.content {
        color: #daa520;
        font-size: 14px;
        text-decoration: underline;
      }
    }

    .mail-detail-list {
      gap: 8px;

      hr {
        margin: 14px 0;
      }

      span.country-title {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        margin-top: 24px;

        &:first-of-type {
          margin-top: 0;
        }
      }

      span.post-title {
        font-weight: bold;
        margin-top: 12px;

        &:first-of-type {
          margin-top: 0;
        }
      }
    }
  }
}
