html {
    scroll-behavior: smooth !important;
}

.ql-editor{
    min-height: 200px !important;
    max-height: 300px;
    overflow: hidden;
    overflow-y: scroll;
    overflow-x: scroll;
}

.full-page-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.auth-page-wrapper {
    flex-direction: column;
    gap: 42px;
    background: #00152a;

    .logo {
        width: 200px;
    }

    form {
        width: 400px;

        > a {
            color: #8e8f91;
            display: inline-block;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.sidebar-logo {
    width: 70%;
    margin: 42px 32px 24px;
}

.sidebar-small-logo {
    width: 100%;
    margin: 42px 0 24px;
    padding: 10px;
}

.breadcrumb-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0 8px;
}

// Table
.table-actions {
    justify-content: flex-end;
    flex-flow: nowrap;
}
.table-filter {
    width: 600px;
    text-align: right;
    justify-content: flex-end;
}
.table-right-item {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.table-right-item.with-validation {
    align-items: flex-start;
    margin-top: 4px;
}

.color-picker .ant-form-item-control-input-content {
    display: flex !important;
    align-items: center;
    gap: 6px;

    input[type="color"] {
        background: none;
        outline: none;
        height: 32px;
        width: 32px;
        border: 2px solid lightgrey;
        border-radius: 100%;
        text-align: center;

        &::-webkit-color-swatch {
            border: none;
            border-radius: 100%;
            padding: 0;
        }

        &::-webkit-color-swatch-wrapper {
            border: none;
            border-radius: 100%;
            padding: 0;
            cursor: pointer;
          }
    }

    .color-text {
        font-weight: 500;
        background: none;
        outline: none;
        width: 90px;
        border: 1px solid lightgrey;
        border-radius: 5px;
        padding: 4px;
    }
}

.reset-filter-button {
    margin: 0 6px;
    background: #d8d7d7;
}

.ant-layout.site-layout {
    margin-bottom: 16px;
}

.ant-upload-list {
    display: none !important;
}

.sidebar-submenu-active > div {
    color: white;
}

.disabled-row {
    opacity: 0.5;
    transition: 180ms;

    &:hover {
        opacity: 1;
    }
}