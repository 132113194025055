@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.et_pb_post_content_0_tb_body {
  color: #666;

  h1, h2, h3, h4, h5, h6 {
    color: #333;
    padding-bottom: 10px;
    line-height: 1em;
    margin: 0;
  }

  p {
    margin: 0;
    padding-bottom: 1em;
  }
}
.et_pb_post_content_0_tb_body p{line-height:1.8em}.et_pb_post_content_0_tb_body ol li{line-height:1.8em}.et_pb_post_content_0_tb_body{font-family:'Raleway',Helvetica,Arial,Lucida,sans-serif;font-size:18px;line-height:1.8em;text-align:left;font-weight:400}.et_pb_post_content_0_tb_body.et_pb_post_content a{color:#9c785c!important}.et_pb_post_content_0_tb_body a{font-family:'Raleway',Helvetica,Arial,Lucida,sans-serif;font-weight:700}.et_pb_post_content_0_tb_body ul li{line-height:1.8em;font-family:'Raleway',Helvetica,Arial,Lucida,sans-serif;line-height:1.8em;list-style-position:inside}.et_pb_post_content_0_tb_body ul{padding-left:20px!important}.et_pb_post_content_0_tb_body ol{font-family:'Raleway',Helvetica,Arial,Lucida,sans-serif;line-height:1.8em;list-style-type:decimal-leading-zero!important;padding-left:20px!important}.et_pb_post_content_0_tb_body blockquote{font-family:'Raleway',Helvetica,Arial,Lucida,sans-serif;font-weight:300;font-style:italic;font-size:28px;border-width:20px;border-color:#000000}.et_pb_post_content_0_tb_body h1{font-family:'Raleway',Helvetica,Arial,Lucida,sans-serif;font-weight:600;line-height:1.3em}.et_pb_post_content_0_tb_body h2{font-family:'Raleway',Helvetica,Arial,Lucida,sans-serif;font-weight:500;font-size:30px;line-height:1.2em}.et_pb_post_content_0_tb_body h3{font-family:'Raleway',Helvetica,Arial,Lucida,sans-serif;font-weight:500;font-size:26px;line-height:1.3em}.et_pb_post_content_0_tb_body h4{font-family:'Raleway',Helvetica,Arial,Lucida,sans-serif;font-weight:500;font-size:30px;line-height:1.3em}.et_pb_post_content_0_tb_body h5{font-family:'Raleway',Helvetica,Arial,Lucida,sans-serif;font-weight:500;font-size:30px;line-height:1.3em}.et_pb_post_content_0_tb_body h6{font-family:'Raleway',Helvetica,Arial,Lucida,sans-serif;font-weight:500;font-size:30px;line-height:1.3em}.et_pb_post_content_0_tb_body img{border-radius:8px 8px 8px 8px;overflow:hidden;box-shadow:0px 16px 96px -24px rgba(0,0,0,0.3)}
