.drag-and-drop-mail-generator {
  display: flex;
  flex-direction: column;

  .list-item {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 20px 16px;
    border: 2px solid #ededed;
    border-radius: 7px;
    margin-bottom: 16px;
    background: #fafafa;

    &:last-of-type {
      margin-bottom: 0;
    }

    & .disabled-item-btn-icon {
      color: #fd6060;
    }

    .column-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;

      .column-header-item {
        display: flex;
        align-items: center;
        gap: 16px;

        &.column-left {
          flex: 1;

          input {
            font-size: 16px;
            padding: 3px 0;
            border-bottom: 1px solid #c5c5c5;
            border-radius: 0;
          }
        }

        > span.title {
          font-size: 18px;
          font-weight: bold;
        }

        .column-edit-input {
          font-size: 18px !important;
          font-weight: bold;
        }

        &.column-right {
          gap: 4px;

          button {
            width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    .post-list {
      padding: 12px;
      margin: 0 12px;
      border: 2px solid #ededed;
      border-radius: 7px;
      background: white;
      transition: all .1s ease;

      .post-list-item {
        padding: 8px 14px;
        margin-bottom: 12px;
        transition: all .1s ease;

        &:last-of-type {
          margin-bottom: 0;
        }

        &.invisible {
          opacity: 0.4;
          transition: 180ms;
          &:hover {
            opacity: 1;
          }
        }

        .column-header span.title {
          font-size: 16px;
          font-weight: normal;
        }

        svg {
          font-size: 18px;
        }
      }
    }
  }
}
